@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');


@font-face {
  font-family: 'comicate';
  src: url('../public/font/COMICATE.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'archivio';
  src: url('../public/font/Archivo-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Space Grotesk';
  src: url('../public/font/SpaceGrotesk-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'spacemono';
  src: url('../public/font/SpaceMono-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'acorn';
  src: url('../public/font/acorn-3.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'helveticaneue';
  src: url('../public/font/HelveticaNeue-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'suisse';
  src: url('../public/font/SUISSE.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  overflow-x: hidden;
  width: 100%;
}

body {
  font-family: 'Inter', sans-serif;
  color: white;
}


